<template>
  <div class="row">

    <div class="col-12">

      <card>
        <div slot="header" class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap">
          <h4 class="card-title">Staff List</h4>
        </div>

        <div>
          <general-data-table
            ref="table"
            :method="'POST'"
            :row-key="'id'"
            :key="componentKey"
            :api-url="'staff/index'">
            <el-table-column v-for="column in tableColumns"
                             :key="column.label"
                             :min-width="column.minWidth"
                             :align="column.align"
                             :sortable="column.sortable"
                             :prop="column.value"
                             :label="column.label">
            </el-table-column>

            <el-table-column
              :min-width="120"
              align="center"
              label="Active">
              <template slot-scope="props">

                <l-switch v-if="$store.getters['auth/haveOneOfPermissions'](['staff/change-active-status'])"
                          v-model="props.row.is_active" @input="handleActiveStatus(props.row)">
                  <i class="fa fa-check" slot="on"></i>
                  <i class="fa fa-times" slot="off"></i>
                </l-switch>

              </template>
            </el-table-column>

            <el-table-column
              :min-width="120"
              :fixed="'right'"
              align="center"
              label="Actions">
              <template slot-scope="scope">
                <router-link v-if="$store.getters['auth/haveOneOfPermissions'](['staff/view-subscriptions'])"
                             v-tooltip.top-center="'View Subscriptions'"
                             class="btn-success btn-simple btn-link"
                             :to="'/staff/view-subscriptions/'+scope.row.id">
                  <i class="fa fa-eye"></i></router-link>

                  <router-link v-if="$store.getters['auth/checkAccess']('staff/change-password')"
                             v-tooltip.top-center="'Change Password'" class="btn-info btn-simple btn-link"
                             :to="'/staff/changePassword/'+scope.row.id">
                  <i class="fa fa-key"></i></router-link>

<!--                <router-link v-if="$store.getters['auth/haveOneOfPermissions'](['staff/update'])"-->
<!--                             v-tooltip.top-center="'Edit'"-->
<!--                             class="btn-warning btn-simple btn-link"-->
<!--                             :to="'/schools/edit/'+scope.row.id">-->
<!--                  <i class="fa fa-edit"></i></router-link>-->

<!--                <a v-if="$store.getters['auth/haveOneOfPermissions'](['staff/delete'])"-->
<!--                   v-tooltip.top-center="'Delete'"-->
<!--                   class="btn-danger btn-simple btn-link"-->
<!--                   @click="openDeleteModal(scope.row.id)"><i class="fa fa-times"></i></a>-->
              </template>
            </el-table-column>

          </general-data-table>
        </div>

      </card>


      <delete-modal :visible="deleteModalVisibility"
                    :message="'Are you sure you want to delete this parent?'"
                    @close="closeDeleteModal()"
                    @confirm="confirmDeleteModal()">
      </delete-modal>



    </div>

  </div>
</template>
<script>
import {TableColumn} from 'element-ui'
import {DeleteModal as DeleteModal, NewPagination as NLPagination, Switch as LSwitch} from '@/components'
import GeneralDataTable from "@/components/GeneralDataTable";
import FgSelect from "@/components/Inputs/formGroupSelect";
import ExportModal from '@/components/ExportModal/ExportModal.vue';


export default {
  components: {
    NLPagination,
    DeleteModal,
    [TableColumn.name]: TableColumn,
    LSwitch,
    GeneralDataTable,
    FgSelect,
    ExportModal
  },

  data() {

    return {
      tableColumns: [
        {label: 'First Name', value: 'first_name', minWidth: '200', align: 'center'},
        {label: 'Last Name', value: 'last_name', minWidth: '200', align: 'center'},
        {label: 'Email', value: 'email', minWidth: '200', align: 'center'},
        {label: 'Phone', value: 'phone', minWidth: '200', align: 'center'},
        {label: 'Position', value: 'position', minWidth: '200', align: 'center'},
        {label: 'School', value: 'school.name', minWidth: '200', align: 'center'}
      ],
      modals: {
        basic: false,
        centered: false,
        custom: false,
        confirm: false
      },
      deleteModalVisibility: false,
      toDeleteId: undefined,
      componentKey: 0,
      currentObject: {},
      qrCodeModalVisibility: false,
    }

  },

  methods: {
    handleActiveStatus(row) {
      let data = {
        'is_active': row.is_active,
      }
      this.axios.post("staff/change-active-status/" + row.id, data).then((response) => {
        this.$notify({
          message: "Staff updated successfully",
          timeout: 1000,
          type: 'success'
        });
      }).catch((error) => {
        console.log(error)
      })
    },

    openDeleteModal(id) {
      this.deleteModalVisibility = true;
      this.toDeleteId = id;
    },

    closeDeleteModal() {
      this.deleteModalVisibility = false;
    },

    confirmDeleteModal() {

      let data = {
        'id': this.toDeleteId,
      }
      this.axios.delete("staff/delete", {headers: {}, data}).then((response) => {
        this.$notify({
          message: "Staff deleted successfully",
          timeout: 1000,
          type: 'success'
        });

        this.$refs.table.getData("updateData");

      }).catch((error) => {
        this.$notify({
          message: error.response.data.message,
          timeout: 2000,
          type: 'danger'
        })
      })

      this.closeDeleteModal();
      this.toDeleteId = undefined;
    },
    openModal(name) {
      this.modals[name] = true
    },
    closeModal(name) {
      this.modals[name] = false
    },
    openQrCodeModal(currentObject) {
      this.currentObject = currentObject;
      this.qrCodeModalVisibility = true;
    },
    closeQrCodeModal() {
      this.qrCodeModalVisibility = false;
    },

  },

}
</script>
